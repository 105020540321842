.mco-container-headline + .mco-container-headline {
	margin-top: 28px;
}
.mco-container-headline-7 + .mco-container-headline {
	margin-top: 8px;
}
body.no-animation .mco-container-headline-7 + .mco-container-headline h1 {
	color: #575654;
}
.mco-container-headline + .mco-container-spacing {
	margin-top: 20px;
}
.mco-container-headline + .mco-container-text {
	margin-top: 28px;
}
.mco-container-download + .mco-container-download {
	margin-top: 24px;
}
.mco-container-table + .mco-container-table {
	margin-top: 56px;
}
.mco-container-singleteaser + .mco-container-singleteaser {
	margin-top: 56px;
}
.mco-container-external-singleteaser + .mco-container-external-singleteaser {
	margin-top: 56px;
}
.mco-container-headline + .mco-container-dropdown {
	margin-top: 40px;
}
.mco-container-dropdown + .mco-container-dropdown {
	margin-top: -16px;
}
.mco-container-spacing + .mco-container-teaser-list-custom,
.mco-container-spacing + .mco-container-teaser-list-custom-with-marks {
	margin-top: 0;
}
.mco-content:not(.no-animation) > .mco-container-job-list:last-child {
	margin-bottom: -120px;
}
.mco-container-job-list + .mco-container-job-list {
	margin-top: -60px;
	padding-top: 0 !important;
}
.mco-container-team + .mco-container-team {
	margin-top: 60px !important;
}
.mco-container-team + .mco-container-team > div {
	border-top: 1px solid #bcb5ab;
	padding-top: 48px !important;
}
@media all and (min-width: 960px) {
	.mco-content:not(.no-animation) > .mco-container-job-list:last-child {
		margin-bottom: -100px;
	}
}
