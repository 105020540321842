@import url("./footer.css");
@import url("./intro.css");
@import url("./collaborate.css");
@import url("./custom-elements.css");

html {
	font-size: 100%;
	min-height: calc(100% + env(safe-area-inset-top));
	scroll-behavior: smooth;
	scroll-padding-top: 100px;
}
body {
	margin: 0;
	scrollbar-gutter: stable;
}
a {
	color: var(--mco-palette-primary-main);
	font-weight: normal !important;
	text-decoration: none;
}
p {
	margin: 0;
}
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
strong {
	font-family: "Marat-Sans-Semibold";
	font-weight: 600;
}
#root {
	padding: 0;
	position: relative;
}
.mco-content:not(.no-animation) .mco-container:first-child {
	margin-top: 0;
}
*:not(.no-custom-scrollbar) {
	scrollbar-color: var(--mco-palette-primary-main) transparent;
	scrollbar-width: thin;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar {
	width: 10px;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar-track {
	background: transparent;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar-thumb {
	background-color: var(--mco-palette-primary-main);
	border: 0 solid transparent;
	border-radius: 0;
}
