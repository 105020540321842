/* Layout */

/* Button */

button > .MuiButton-label {
	margin-top: 1px;
}

/* Überschrift */

/*** Headline ***/

.mco-container-headline-1,
.mco-container-headline-2,
.mco-container-headline-3,
.mco-container-headline-4,
.mco-container-headline-5,
.mco-container-headline-6,
.mco-container-headline-7 {
	text-align: center;
}
.mco-container-headline-1 h1,
.mco-container-headline-2 h2,
.mco-container-headline-3 h3,
.mco-container-headline-4 h4,
.mco-container-headline-5 h5,
.mco-container-headline-6 h6 {
	color: #ffffff;
}
.no-animation .mco-container-headline-1 h1 {
	color: #9f9587;
}
.no-animation .mco-container-headline-2 h2,
.no-animation .mco-container-headline-3 h3,
.no-animation .mco-container-headline-4 h4,
.no-animation .mco-container-headline-5 h5,
.no-animation .mco-container-headline-6 h6 {
	color: #575654;
}
.mco-container-headline-7 span {
	color: var(--mco-palette-secondary-main);
}

/* Text */

.mco-container-text .mco-text-01 p {
	color: #ffffff;
}
.mco-container-text-1 .mco-text-01 p {
	color: #ffffff;
	text-align: center;
}
body.no-animation .mco-container-text .mco-text-01 p,
body.no-animation .mco-container-text-1 .mco-text-01 p {
	color: #575654 !important;
}

/* Button */

.mco-container-extension-button {
	margin-top: 56px !important;
}

/* List */

.mco-container-list .mco-list-01__headline {
	color: #ffffff;
	margin-bottom: 32px;
	text-align: center;
}
body.no-animation .mco-container-list .mco-list-01__headline {
	color: #575654 !important;
}
.mco-container-list .mco-list-01__list-item svg {
	margin-right: 16px;
	margin-top: 3px;
}
.mco-list-01__list .mco-list-01__list-item > span {
	color: #ffffff !important;
}
body.no-animation .mco-list-01__list .mco-list-01__list-item > span {
	color: #575654 !important;
}

/* Table */

.mco-table-01 .mco-table-01__headline {
	margin-bottom: 32px;
	text-align: center;
}
.mco-table-01 .mco-table-01__cell {
	font-size: 18px;
	font-weight: normal;
}
.mco-table-01 .mco-table-01__cell strong {
	font-weight: bold;
}
.mco-table-01 .MuiTableCell-head {
	background: var(--mco-palette-background-teaser-light);
}
.mco-table-01 .mco-table-01__swipePopUp p {
	color: #ffffff !important;
}
.mco-table-01 .mco-table-01__swipePopUp svg path {
	fill: #ffffff !important;
}
.mco-table-01 .mco-table-01__footer p {
	font-size: 12px;
}
@media all and (min-width: 1280px) {
	.mco-table-01 .mco-table-01__cell {
		font-size: 18px;
		font-weight: normal;
	}
}

/* Singleimage */

.mco-container-singleimage .mco-singleimage-01__content {
	background: var(--mco-palette-background-body-dark);
	padding: 16px 20px;
	text-align: center;
}
.mco-container-singleimage .mco-singleimage-01__content > p {
	color: var(--mco-palette-secondary-main);
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.15em;
	text-transform: uppercase;
}
.mco-container-singleimage .mco-singleimage-01__content > div {
	color: #ffffff;
	font-family: "Marat-Sans-Regular";
	font-weight: normal;
}
.mco-container-singleimage .mco-singleimage-01__content > p + div {
	margin-top: 8px;
}

@media all and (min-width: 600px) {
	.mco-container-singleimage .mco-singleimage-01 {
		display: grid;
		grid-template-rows: repeat(7, auto);
		grid-template-columns: auto;
	}
	.mco-container-singleimage .mco-singleimage-01__image {
		grid-row: 1 / 7;
		width: 100%;
		grid-column: 1 / 2;
	}
	.mco-container-singleimage .mco-singleimage-01__content {
		grid-row: 6 / 8;
		grid-column: 1 / 2;
		margin: 0 auto;
		max-width: 500px;
	}
}
@media all and (min-width: 1280px) {
	.mco-container-singleimage .mco-singleimage-01__content {
		grid-row: 5 / 8;
		max-width: 600px;
		padding: 40px;
	}
}
@media all and (min-width: 1920px) {
	.mco-container-singleimage .mco-singleimage-01__content {
		max-width: 732px;
		padding: 70px;
	}
}

/* Bild-Text Kombination */

.mco-imagetext-01 {
	display: flex;
	flex-direction: column;
}
.mco-imagetext-01__text {
	color: #ffffff;
	font-size: clamp(32px, 6vw, 55px) !important;
	font-family: "Marat-Regular" !important;
	line-height: 1 !important;
	margin-bottom: 32px !important;
}
body.no-animation .mco-imagetext-01__text {
	color: #575654 !important;
}
.mco-imagetext-01__image {
	margin-bottom: 0 !important;
}

@media all and (min-width: 960px) {
	.mco-imagetext-01 {
		grid-column-gap: 78px !important;
		grid-template-columns: 1fr 1.5fr !important;
	}
	.mco-imagetext-01__text {
		margin: auto 0 0 !important;
		text-align: right !important;
	}
}

@media all and (min-width: 1280px) {
	.mco-imagetext-01 {
		grid-template-columns: 1fr 1.6fr !important;
	}
}

/* References */

.mco-references-01 .mco-references-01__headline {
	margin-bottom: 24px;
	text-align: center;
}
.mco-references-01 a {
	align-items: center;
	display: flex;
}
.mco-references-01 a > p {
	font-size: 18px;
	padding-right: 8px;
	transition: color 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		padding-right 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.mco-references-01 a:hover > p {
	color: var(--mco-palette-primary-main);
	padding-right: 12px;
}
.mco-references-01 a svg path {
	fill: var(--mco-palette-secondary-main);
	opacity: 0.5;
	transition: fill 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		opacity 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.mco-references-01 a:hover svg path {
	fill: var(--mco-palette-primary-main);
	opacity: 1;
}
.mco-references-02 .mco-references-02__list-item button {
	color: #ffffff;
	height: auto;
	padding: 6px 16px;
	text-align: left;
}
.mco-references-02 .mco-references-02__list-item button p {
	font-family: "Marat-Sans-Semibold";
	font-size: 18px;
	letter-spacing: 0.1em;
}

/* Video */

.mco-container-video .mco-video-02 {
	padding: 0 !important;
}
.mco-container-video .mco-video-02__content {
	border: 1px solid #bcb5ab;
	border-left: none;
	border-right: none;
}
.mco-container-video .mco-video-02__content h3 {
	color: var(--mco-palette-secondary-main);
	font-size: 35px;
}
.mco-container-video .mco-video-02__image-wrapper svg {
	fill: #ffffff;
}
.mco-container-video .mco-video-02__image-wrapper .mco-video-02__play-icon {
	opacity: 0.8;
	transition: opacity 500ms ease;
}
.mco-container-video .mco-video-02__image-wrapper:hover .mco-video-02__play-icon {
	opacity: 1;
}

@media all and (min-width: 960px) {
	.mco-video-02 > div {
		padding-right: 32px;
	}
	.mco-container-video .mco-video-02__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
}

/* Download */

.mco-container-download > div {
	border-bottom: 1px solid #bcb5ab;
	border-top: 1px solid #bcb5ab;
	flex: 1;
	padding-bottom: 8px;
	padding-top: 8px;
}

/* Dropdown */

.mco-container-dropdown .MuiAccordionDetails-root > div > .mco-container:first-child {
	margin-top: 0;
}
.mco-container-dropdown .mco-container {
	max-width: none;
}

/* Formular */

.mco-container-form::before {
	background: #ffffff;
	content: "";
	height: 100%;
	left: -4000px;
	position: absolute;
	pointer-events: none;
	top: 0;
	width: 8000px;
	z-index: -1;
}
body:not(.no-animation) .mco-container-form > form {
	padding-bottom: 40px !important;
	padding-top: 60px !important;
}
.mco-form .MuiFormLabel-root {
	color: #575654 !important;
}
.mco-form .mco-form-headline {
	margin: 16px 0 !important;
}
.mco-form .mco-form-headline p {
	font-size: 35px !important;
	line-height: 1 !important;
	text-align: center !important;
}
.mco-form .mco-form-button-wrapper {
	padding: 24px 0 !important;
	text-align: center !important;
}
.mco-form .mco-form-button-wrapper button {
	background: #9f9587 !important;
}
.mco-form .mco-form-button-wrapper button:hover {
	background: #575654 !important;
}
.mco-form .MuiCheckbox-root {
	color: #9f9587 !important;
}
.mco-form-message-error {
	position: relative;
}

@media all and (min-width: 960px) {
	body:not(.no-animation) .mco-container-form > form {
		padding-bottom: 60px !important;
		padding-top: 80px !important;
	}
}

/* Teaser Slider */

@media all and (min-width: 960px) {
	.mco-container-teaser-list-custom-with-marks {
		margin-bottom: -120px;
	}
}

/* Gallery */

.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div {
	overflow: hidden;
}
.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div > div:before {
	background: linear-gradient(-180deg, rgba(32, 48, 61, 0) 54.66%, #20303d 98.41%);
	content: "";
	opacity: 0;
	transition: opacity 500ms ease;
	position: absolute;
	inset: 0;
	z-index: 1;
}
.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div > div:after {
	bottom: 14px;
	content: "zoom";
	color: #ffffff;
	font-size: 14px;
	opacity: 0;
	transition: opacity 500ms ease;
	position: absolute;
	text-transform: uppercase;
	left: 16px;
	z-index: 1;
}
.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div img {
	transition: transform 800ms ease;
}
.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__download button {
	font-size: 16px;
}

@media (hover: hover) {
	.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div > div:hover:before {
		opacity: 1;
	}
	.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div > div:hover:after {
		opacity: 1;
	}
	.mco-gallery-03 .mco-gallery-03__grid .mco-gallery-03__column > div > div:hover img {
		transform: scale(1.1) rotate(5deg);
	}
}

.mco-gallery-04 .swiper-container {
	padding-bottom: 48px;
}
.mco-gallery-04 .mco-gallery-04-swiper-nav__prev,
.mco-gallery-04 .mco-gallery-04-swiper-nav__next {
	top: calc(50% - 20px);
	transition: background 500ms ease;
}
.mco-gallery-04 .mco-gallery-04-swiper-nav__prev:hover,
.mco-gallery-04 .mco-gallery-04-swiper-nav__next:hover {
	background: rgba(0, 0, 0, 0.4);
}
.mco-gallery-04 .swiper-pagination {
	bottom: 8px !important;
}
.mco-gallery-04 .swiper-pagination .swiper-pagination-bullet {
	background: var(--mco-palette-secondary-main);
	border-radius: var(--mco-shape-border-radius);
	height: 12px;
	margin: 0 5px;
	transform: rotate(45deg);
	width: 12px;
}
.mco-gallery-04 .swiper-pagination .swiper-pagination-bullet-active {
	background: var(--mco-palette-primary-main);
}

/* Teaser */

.mco-container-teaser-list .swiper-container {
	overflow: visible;
}
.mco-container-teaser-list .swiper-pagination {
	bottom: 0 !important;
	position: relative !important;
}
.mco-container-teaser-list .swiper-pagination .swiper-pagination-bullet {
	background: var(--mco-palette-secondary-main);
	border-radius: var(--mco-shape-border-radius);
	height: 12px;
	margin: 0 5px;
	transform: rotate(45deg);
	width: 12px;
}
.mco-container-teaser-list .swiper-pagination .swiper-pagination-bullet-active {
	background: var(--mco-palette-primary-main);
}
.mco-teaser-01 {
	background: var(--mco-palette-background-teaser-light) !important;
	border-bottom-left-radius: var(--mco-shape-border-radius);
	border-bottom-right-radius: var(--mco-shape-border-radius);
}
.mco-teaser-01 .mco-teaser-01__title {
	color: var(--mco-palette-secondary-dark) !important;
	font-family: "Marat-Regular";
	font-size: 28px;
}
.mco-teaser-01 .mco-teaser-01__content button {
	padding-left: 0;
	padding-right: 0;
	transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.mco-teaser-01 .mco-teaser-01__content button:hover {
	background: none !important;
	color: var(--mco-palette-primary-main);
}
.mco-teaser-01 img {
	border-top-left-radius: var(--mco-shape-border-radius);
	border-top-right-radius: var(--mco-shape-border-radius);
}
@media all and (min-width: 600px) {
	.mco-container-teaser-list .swiper-container {
		overflow: hidden;
	}
}

/* Singleteaser */

.mco-singleteaser-01 .mco-singleteaser-01__content {
	background: var(--mco-palette-background-body-dark);
	border: none;
}
.mco-singleteaser-01 .mco-singleteaser-01__title {
	font-family: "Marat-Sans-Regular";
	font-weight: normal;
	font-size: 18px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	margin-bottom: 16px;
}
.mco-singleteaser-01 .mco-singleteaser-01__text {
	color: #f5f1eb;
}
.mco-singleteaser-01 .mco-singleteaser-01__content button {
	background: none;
	color: var(--mco-palette-secondary-main);
	font-size: 1.2767857142857142rem;
	height: 36px;
	margin-left: auto;
	padding-left: 0;
	padding-right: 0;
	transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	width: auto;
}
.mco-singleteaser-01 .mco-singleteaser-01__content button:hover {
	background: none !important;
	color: var(--mco-palette-primary-main);
}
@media all and (min-width: 960px) {
	.mco-singleteaser-01 .mco-singleteaser-01__title {
		font-size: 22px;
	}
}

/* Overlay */

.mco-overlay .mco-overlay-toolbar {
	background: var(--mco-palette-background-body-dark);
}
.mco-overlay .mco-overlay-toolbar-title {
	color: #ffffff;
}
.mco-overlay .mco-overlay-toolbar svg path {
	fill: #ffffff;
}
.mco-overlay .mco-overlay-iframe-wrapper img {
	height: 100%;
	object-fit: contain;
	width: 100%;
}
.mco-overlay .mco-overlay-arrow-next {
	align-items: center;
	background: linear-gradient(90deg, rgba(32, 48, 61, 0.2) 54.66%, #20303d 98.41%);
	display: flex;
	height: 100px;
	right: 0 !important;
	z-index: 1;
}
.mco-overlay .mco-overlay-arrow-prev {
	align-items: center;
	background: linear-gradient(-90deg, rgba(32, 48, 61, 0.2) 54.66%, #20303d 98.41%);
	display: flex;
	height: 100px;
	left: 0 !important;
	z-index: 1;
}

/* Lang Selection */

.mco-overlay.overlay-lang .mco-overlay-toolbar {
	background: var(--mco-palette-primary-main) !important;
}
.mco-language-switch__entry a:hover {
	background: var(--mco-palette-primary-main) !important;
}

/* Other */

.MuiInputBase-root .MuiSelect-icon {
	height: 24px;
	width: 24px;
}

@media all and (min-width: 1280px) {
	.mco-content.map {
		padding-top: 100px !important;
	}
}

/* Job List */

.mco-container-job-list {
	background: #ffffff !important;
	padding-bottom: 100px !important;
	padding-top: 100px !important;
}
.mco-container-job-list > div {
	max-width: 848px !important;
}
.job-list__headline {
	margin-bottom: 48px !important;
	text-align: center;
}
.mco-job-teaser__button.MuiButton-outlinedPrimary {
	border-color: #575654 !important;
	color: #575654;
}
.mco-job-teaser__content-icon {
	align-items: center;
	background: #c85751 !important;
	display: flex;
	height: 40px;
	justify-content: center;
	margin-top: -4px;
	width: 44px;
}
.mco-job-teaser__title {
	font-size: clamp(24px, 5vw, 26px) !important;
}

@media all and (min-width: 960px) {
	.mco-content.map {
		padding-bottom: 150px !important;
		padding-top: 150px !important;
	}
}

/* Consent-Management */

.mco-consent-pop-up,
.mco-overlay-content {
	border-radius: 4px !important;
}
.mco-consent-configuration__toolbar {
	background: var(--mco-palette-background-teaser-light) !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
}
.mco-consent-configuration__toolbar p {
	color: #20303d !important;
}
.mco-consent-configuration__groups > div .MuiAccordionSummary-root {
	background: var(--mco-palette-background-teaser-light) !important;
}
.mco-consent-configuration__groups .MuiAccordionDetails-root > div > div {
	padding-top: 4px !important;
}
.mco-consent button {
	box-shadow: none !important;
	border-radius: 4px !important;
	font-family: "Marat-Sans-Semibold" !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	height: 48px !important;
	letter-spacing: 0.1em !important;
	line-height: 1 !important;
	padding: 10px 15px !important;
	transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}
.mco-consent button.MuiButton-outlined {
	padding: 9px 15px !important;
}
.mco-consent-pop-up__button-confirm:hover {
	background: var(--mco-palette-primary-dark) !important;
}
.mco-consent-pop-up__text {
	font-size: 18px !important;
	text-align: center !important;
}
.mco-consent-configuration__text {
	font-size: 18px !important;
}
.mco-consent-pop-up__privacy {
	color: var(--mco-palette-primary-main) !important;
}
.mco-consent-pop-up__privacy:hover {
	color: var(--mco-palette-primary-dark) !important;
}

/* Benefits */

.mco-container-benefits {
	background: #ffffff !important;
}
.mco-container-benefits > div {
	padding-bottom: 60px !important;
	padding-top: 60px !important;
}

@media all and (min-width: 960px) {
	.mco-container-benefits > div {
		padding-bottom: 80px !important;
		padding-top: 80px !important;
	}
}

/* Interaktionslayer */

.micado-ial-item-newsletter__form-button button,
.micado-ial-item-countdown__button,
.micado-ial-item-message__button,
.micado-ial-item-offer__button {
	background: var(--mco-palette-primary-main);
	border: 0;
	border-radius: 4px;
	box-shadow: none !important;
	cursor: pointer;
	font-family: "Marat-Sans-Semibold";
	font-size: 20px;
	font-weight: 600;
	height: 48px;
	letter-spacing: 0.1em;
	line-height: 48px;
	min-width: 64px;
	padding: 0 24px;
	text-align: center;
	text-transform: uppercase;
	transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	width: auto !important;
}
.micado-ial-item-newsletter__form-button:hover button,
.micado-ial-item-countdown__button:hover,
.micado-ial-item-message__button:hover,
.micado-ial-item-offer__button:hover {
	background: var(--mco-palette-primary-dark);
}
.micado-ial-item-notification__button {
	border-radius: 4px;
}
.micado-ial__notification .micado-ial__close:hover .micado-svg-icon svg path,
.micado-ial__message .micado-ial__close:hover .micado-svg-icon svg path {
	fill: #ffffff;
}
.micado-ial-item-notification__button--is-icon:hover {
	background: var(--mco-palette-primary-dark);
}
.micado-ial__close:hover {
	background: #bcb5ab !important;
}
.micado-ial-item-message__buttons {
	justify-content: flex-end !important;
}
